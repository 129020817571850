.form-control {


}

.filter-select {
	border: none;
}

.filter-input {
	position: relative;

	.form-control {
		border-bottom: 1px solid $another-grey;
		border-right: none;
		border-left: none;
		border-top: none;
		border-radius: 0;
		padding-left: 0;

		&::placeholder {
			text-transform: uppercase;
			font-weight: 600;
		}

		&:focus, &:active {
			box-shadow: none;
			outline: none;
		}
	}

	&::after {
		font-family: 'Font Awesome 6 Pro';
		transform: translateY(-50%);
		pointer-events: none;
		position: absolute;
		content: '\f002';
		font-weight: 900;
		display: block;
		color: $blue;
		right: 0;
		top: 50%;
	}
}