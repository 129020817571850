$white: #ffffff;
$blue: #254476;
$dark-blue: #0b1f3e;
$grey: #8C8B8B;
$light-grey: #f2f2f2;
$another-grey: #96989B;
$dark-grey: #777777;
$darker-grey: #333333;

$body-color: $darker-grey;

$font-family-raleway: 'Raleway', sans-serif;
$font-family-base: $font-family-raleway;

$headings-font-weight: 600;

$enable-negative-margins: true;

$menu-colour: ffffff;
$navbar-toggler-icon-home-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23254476' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-toggler-border-color: rgba($white, 1);

$header-height: 120px;

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1400px,
		xxxl: 1600px,
		fw: 1900px,
		uw: 2200px
);

$grid-gutters: (
		xs: 25px,
		sm: 50px,
		md: 75px,
		lg: 65px,
		xl: 100px,
		xxl: 130px,
		xxxl: 160px,
		fw: 200px,
		uw: 300px
);

$fancybox-max-widths: (
		xs: 400px,
		md: 580px,
		lg: 780px,
		xl: 1120px,
		//xxxl: 1280px,
		//fw: 1380px
);

$utilities: (
		"height": (
				property: height,
				responsive: true,
				class: h,
				values: (
						25: 25%,
						50: 50%,
						75: 75%,
						100: 100%,
						auto: auto
				)
		),
);

$spacer: 1rem;
$spacers: (
		0: 0,
		1: 0.25rem,
		2: 0.5rem,
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 2,
		6: $spacer * 2.5,
		7: $spacer * 3,
		8: $spacer * 3.5,
		9: $spacer * 4,
		10: $spacer * 4.5,
		11: $spacer * 5,
		12: $spacer * 5.5,
		13: $spacer * 6,
		14: $spacer * 6.5,
		15: $spacer * 7,
		16: $spacer * 7.5,
		17: $spacer * 8,
		18: $spacer * 8.5,
		19: $spacer * 9,
		20: $spacer * 9.5,
		21: $spacer * 10,
		22: $spacer * 10.5,
		23: $spacer * 11,
		24: $spacer * 11.5,
		25: $spacer * 12,
		26: $spacer * 12.5,
		27: $spacer * 13,
		28: $spacer * 13.5,
		29: $spacer * 14,
		30: $spacer * 32,
);
