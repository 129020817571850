.content-card-container {
	row-gap: 60px;

	.content-card {
		padding: 0 30px;
	}

	.inner-content-card {
		border: none !important;
	}

	.content-card:nth-child(1),
	.content-card:nth-child(4) {
		.inner-content-card {
			background-color: $light-grey;
		}
	}

	.content-card:nth-child(2),
	.content-card:nth-child(3) {
		.inner-content-card {
			background-color: $blue;

			.title {
				color: white !important;
			}

			p {
				color: white !important;
			}
		}
	}

	.title {
		font-size: 24px;
	}
}
