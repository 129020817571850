footer {
	> div {
		background-color: $blue;
		color: white;
	}

	ul {
		align-items: center;
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;

		li {
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				transition: color 175ms ease-out;
				text-decoration: none;
				color: white;

				&:hover {
					color: rgba(255, 255, 255, 0.75);
				}
			}
		}

		&.footer-items {
			li {
				font-size: 0.85rem;
				margin-right: 0;

				@media screen and (min-width: 768px) {
					margin-right: 1rem;

					&::after {
						content: " | ";
						display: inline-block;
						margin-left: 1rem;
					}

					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}

}