.full-width-content-image-container {
	.fwcic-text {
		padding: 10%;

		h1, h2, h3, h4, h5, h6 {
			color: $blue;
			font-weight: 900;
			padding-bottom: 1.75rem;
		}
	}
	.fwcic-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		&__img {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}
}
