.full-width-banner-container {
	background-color: $light-grey;
	padding: 7rem 1rem;
	margin-top: 5rem;

	.heading {
		color: $blue;
		text-transform: uppercase;
		font-weight: 800;
		letter-spacing: 0.3px;
		font-size: 1.75rem;
		line-height: 1.2;
	}
}
