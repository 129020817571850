p {
	letter-spacing: 0.2px;
	color: $darker-grey;
	font-weight: 500;
	font-size: 1rem;

	&.font-lg {
		font-size: 1.25rem;
	}

	&.font-sm {
		font-size: 0.875rem;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

strong {
	font-weight: 800;
}

.text-blue {
	color: $blue !important;
}

.text-grey {
	color: $grey !important;
}

.text-dark-grey {
	color: $dark-grey !important;
}
