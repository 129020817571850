.product-entry {
	.product-summary {

	}

	.product-image {
		max-width: 100%;
		height: auto;
	}

	.info-table-container {
		table {
			width: 100%;

			tr {
				@media screen and (min-width: 767px) {
					&:last-child {
						td span {
							border-bottom: none;
						}
					}
				}

				td {


					span {
						border-bottom: 2px solid $grey;
						display: block;
						margin-top: 6px;
						padding-bottom: 6px;
						width: 100%;
					}

					&:first-child {
						width: 200px;
						font-weight: 700;
						color: black;
						padding-right: 7px;
					}

					&:last-child {
						padding-left: 7px;
					}
				}
			}
		}
	}

	.product-downloads-title {
		font-weight: 600;
		font-size: 1.7rem;
		line-height: 1.2;
		margin-bottom: 1rem;
		color: white;
	}

	.product-downloads-container {
		display: flex;
		column-gap: 1.5rem;
		row-gap: 1.5rem;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 2rem;
		padding-left: 3rem;
		padding-right: 3rem;

		.product-download {
			color: white;
			border: 3px solid white;
			padding: 0.75rem 1.25rem;
			font-weight: 600;
			text-transform: uppercase;
			text-decoration: none;
			transition: background-color 175ms ease-out, color 175ms ease-out;

			&:hover {
				background-color: white;
				color: $dark-blue;
			}
		}
	}

	.summary-content {
		display: flex;
		align-items: center;
	}

	.physical-properties-container {
		h3 {
			color: $blue;
		}

		.property-columns {
			display: grid;
			grid-template-columns: repeat(1, minmax(0, 1fr));
			column-gap: 2rem;

			@media screen and (min-width: 600px) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@media screen and (min-width: 991px) {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}

			.property {
				border-bottom: 2px solid $grey;
				flex-direction: column;
				display: flex;
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				justify-content: center;

				span {
					color: black;

					&:first-child {
						font-weight: 700;
					}
				}

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}
		}

	}
}
