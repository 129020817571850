.faqs {
  background-color: $blue;
  color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;

  label:after {
    @media (min-width: 768px) {
      content: '';
      background-image: url('/assets/images/arrow-down.png');
      position: absolute;
      right: 1em;
      color: #fff;
      height: 10px;
      width: 19px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      top: 36px;
    }
  }

  input {
    display: none;
  }

  label {
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 20px;
  }

  input:checked + label:after {
    content: '';
    background-image: url('/assets/images/arrow-up.png');
    line-height: .8em;
  }

  .accordion__panel {
    border: 3px solid #fff;
    margin-bottom: 1rem;
    position: relative;
  }

  .accordion__content{
    max-height: 0em;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);

    p {
      color: white;
      font-weight: 300;
    }

    a {
      color: white !important;
      font-weight: 600 !important;
    }

    a:hover {
      color: white;
    }
  }

  input[name='panel']:checked ~ .accordion__content {
    max-height: 50em;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
