header {
	background-color: $blue;

	.header-top {
		border-bottom: 5px solid $blue;
		justify-content: center;
		background-color: white;
		flex-direction: column;
		height: $header-height;
		position: fixed;
		z-index: 10000;
		display: flex;
		width: 100%;
		left: 0;
		top: 0;
	}

	.header-bottom {
		.banner {
			min-height: 380px;
			position: relative;
			display: flex;
			flex-direction: column;

			.nav-container {
				//transition: background-color 300ms ease-in-out;
				position: fixed;
				z-index: 5000;
				top: 120px;
				left: 0;

				@media screen and (max-width: 767px) {
					padding-left: 0;
					padding-right: 0;
				}

				.nav-background {
					background-position: center bottom;
					transition: opacity 200ms ease-out;
					position: absolute;
					height: 100%;
					width: 100%;
					opacity: 0;
					z-index: -1;
					left: 0;
					top: 0;
					//min-height: 360px;


					&::after {
						background-color: rgba(0, 0, 0, 0.55);
						position: absolute;
						display: block;
						height: 100%;
						content: ' ';
						width: 100%;
						z-index: 1;
						left: 0;
						top: 0;
					}
				}

				&.show-background {
					background-color: black;
				}
			}

			.home-container {
				position: absolute;
				bottom: 0;
				left: 0;
			}

			.banner-wrapper {
				background-color: rgba(0, 0, 0, 0.6);
				overflow: hidden;
				background-size: cover;
				background-position: center center;
				flex-grow: 1;

				&::after {
					background-color: rgba(0, 0, 0, 0.60);
					position: absolute;
					content: ' ';
					height: 100%;
					width: 100%;
					z-index: 5;
					left: 0;
					top: 0;
				}

				//@media screen and (max-width: 767px) {
				//	min-height: 360px;
				//	.video-container {
				//		min-height: 360px;
				//	}
				//}

				img {
					position: relative;
					width: 100%;
					z-index: -1;
				}

				.video-container {
					padding-top: 19%;
					overflow: hidden;
					position: fixed;
					width: 100%;

					video {
						transform: translateY(-50%);
						position: absolute;
						width: 100%;
						top: 50%;
						left: 0;
						z-index: 1;
					}
				}

				.content {
					justify-content: center;
					flex-direction: column;
					position: absolute;
					display: flex;
					z-index: 20;
					height: 100%;
					color: white;
					width: 100%;
					left: 0;
					top: 0;

					@media screen and (min-width: 768px) and (max-width:1399px) {
						padding-top: 65px;
					}

					p, h1 {
						color: white;
					}
				}
			}
		}
	}

	.navbar-toggler {
		border: none;

		.navbar-toggler-icon {
			background-image: $navbar-toggler-icon-bg;
		}

		.menu-text {
			position: relative;
			top: 1px;
			color: $blue;
			text-transform: uppercase;
			font-weight: 900;
			letter-spacing: 0.4px;
		}

		&:focus {
			box-shadow: none;
		}
	}

	nav.navbar {
		padding: 0;

		@media screen and (max-width: 767px) {
			.navbar-collapse {
				background-color: $blue;
			}
		}


		ul.navbar-nav {
			li.nav-item {
				a.nav-link {
					transition: background-color 175ms ease-out, color 175ms ease-out;
					text-transform: uppercase;
					padding: 1.25rem 1.75rem;
					font-weight: 800;
					font-size: 1rem;
					color: white;

					&.active, &:hover {
						background-color: $blue;
					}
				}
			}
		}
	}

	ul.social-links {
		align-items: center;
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;

		li {

			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				text-decoration: none;

				&:hover {
				}
			}
		}
	}
}
