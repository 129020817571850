.btn {
	&.btn-primary {
		transition: background-color 175ms ease-out, color 175ms ease-out;
		text-transform: uppercase;
		padding: 0.375rem 1.75rem;
		border: 2px solid $blue;
		background-color: white;
		letter-spacing: 0.4px;
		border-radius: 0;
		font-weight: 900;
		color: $blue;

		&.border-3 {
			border-width: 3px !important;
		}

		&.bg-transparent {
			background-color: transparent;
		}

		&:hover {
			background-color: $blue !important;
			color: white;
		}
	}
}
