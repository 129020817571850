.home-quote-block {
	background-color: $light-grey;
	padding: 7rem 1rem;
	margin-top: 5rem;

	h3 {
		color: $blue;
	}

	.btn-primary {
		background: transparent !important;
		margin-top: 2rem;

		&:hover {
			background-color: $blue !important;
		}
	}
}