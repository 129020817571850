.row.sep-borders {
	@media screen and (min-width:992px) {
		> div {
			border-left: 3px solid $blue;

			&:first-child {
				border-left: none;
			}
		}
	}

}
