@each $breakpoint, $gutter in $grid-gutters {
	@media screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
		body.page-type-homepage #homepagePlayer .video-container {
			&:first-child {
				padding-left: $gutter;
			}
			&:last-child {
				padding-right: $gutter;
			}
		}
	}
}

body.page-type-homepage {
	padding-top: 0;

	header {
		background-color: transparent;
		position: absolute;
		z-index: 1050;
		width: 100%;

		@media screen and (max-width: 767px) {
			> .container-fluid {
				padding-left: 0;
				padding-right: 0;
			}
		}

		nav.navbar {
			.navbar-toggler {
				margin: 1.5rem auto 1.5rem auto;

				.menu-text {
					color: white;
				}
				.navbar-toggler-icon {
					background-image: $navbar-toggler-icon-home-bg;
				}
			}
			ul.navbar-nav li.nav-item {
				border-top: 10px solid $blue;
			}
		}
	}

	main {
		z-index: 1;
	}

	//footer {
	//	position: absolute;
	//	z-index: 1050;
	//	width: 100%;
	//	bottom:0;
	//
	//	> div {
	//		background-color: transparent;
	//	}
	//
	//	.social-links {
	//		li {
	//			a {
	//				@media screen and (max-width: 767px) {
	//					color: $blue;
	//				}
	//
	//				@media screen and (min-width: 768px) {
	//					&:hover {
	//						color: $blue;
	//					}
	//				}
	//			}
	//		}
	//	}
	//}

	#homepagePlayer {
		// min-height: calc(100vh - 120px);
		height: 100vh !important;

		h1 {
			letter-spacing: 0.3px;
			line-height: 2.8rem;
			font-size: 2rem;
			color: white;
		}

		@media screen and (min-width: 1024px) {
			h1 {
				line-height: 4.05rem;
				font-size: 3.4375rem;
			}
		}

		#homepageVideo {
			height:100%;
			position: absolute;
			top:0;
			left:0;
			object-fit: cover;
		}

		.video-container {
			position: relative;
			padding: 0 1.25rem;
			overflow: hidden;
			z-index: 1;

			@media screen and (min-width: 1024px) {
				padding: 0 6rem;
			}

			&.has-overlay {

				&.overlay-white::after {
					background-color: rgba(255, 255, 255, 0.55);
				}

				&.overlay-black::after {
					background-color: rgba(0, 0, 0, 0.55);
				}

				&::after {
					position: absolute;
					content: ' ';
					height: 100%;
					width: 100%;
					z-index: 1000;
					left: 0;
					top: 0;
				}
			}

			img, .content {
				position: relative;
				z-index: 2000;
			}

			video {
				transform: translate(-50%, -50%);
				pointer-events: none;
				position: absolute;
				height: 100%;
				z-index: -1;
				left: 50%;
				top: 50%;
			}

			@media screen and (max-width: 1023px) {
				img {
					max-width: 200px;
				}
			}
		}

	}
}

.home-intro-block {
	@media (min-width: 992px) {
		padding-left: 150px;
    padding-right: 150px;
	}

	h2 {
		color: $blue;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	.col-md-6 {
		padding-left: 3rem !important;
		padding-right: 3rem !important;
	}

	.col-md-6 {
		p {
			font-size: 1.25rem;
		}
	}

	.col-md-6:last-child {
		@media (min-width: 767px) {
			border-left: 3px solid $blue;
		}

		p {
			font-size: 0.9rem;
		}
	}
}

#home-content-wrapper {
	.content-card {
		padding: 30px;
	}

	.inner-content-card {
    border: none;
	}

	.content-card:nth-child(1),
	.content-card:nth-child(4) {
		.inner-content-card {
			background-color: $light-grey;
		}
	}

	.content-card:nth-child(2),
	.content-card:nth-child(3) {
		.inner-content-card {
			background-color: $blue;

			.title {
				color: white !important;
			}

			p {
				color: white !important;
			}
		}
	}

	.title {
		font-size: 24px;
	}
}
