@import 'variables';

@import '~bootstrap/scss/bootstrap';

@each $breakpoint, $gutter in $grid-gutters {
	@media screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
		.container-fluid {
			padding-left: $gutter;
			padding-right: $gutter;
		}
	}
}

html body {
	padding-top: $header-height;

	main {
		background-color: white;
		z-index: 2000;
	}
}

@import 'page-background';

@import 'headings';
@import 'typography';
@import 'fonts';
@import 'header';
@import 'footer';
@import 'cards';
@import 'forms';
@import 'buttons';

@import 'fields/contentCards';
@import 'fields/faqs';
@import 'fields/homeQuoteBlock';
@import 'fields/whyChooseUs';

@import 'pages/homepage';
@import 'pages/newsArchive';
@import 'pages/productsArchive';
@import 'pages/product-entry';

@import 'vizy/heading';
@import 'vizy/columns';
@import 'vizy/tiled-content-cards';
@import 'vizy/full-width-banner';
@import 'vizy/full-width-content-and-image';

@import '~@fancyapps/ui/dist/fancybox.css';

.bg-dark-blue {
	background-color: $dark-blue !important;
}
.bg-blue {
	background-color: $blue !important;
}
.bg-light-grey {
	background-color: $light-grey !important;
}
.text-white {
	color: white !important;
}
.text-transform-none {
	text-transform: none !important;
}

.content {
	&.text-white {
		p {
			color: white;
		}
	}
}

.cms-content {
	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		text-transform: none;
		margin-bottom: 1rem;
		font-size: 1.75rem;
		font-weight: 600;
		color: $blue;
	}

	hr {
		margin: 2.5rem 0;
	}
}

.max-h-\[200px\] {
	max-height: 200px;
}

.w-\[80px\] {
	width: 80px;
}

.min-h-\[125px\] {
	min-height: 125px !important;
}

.form-item {
	.form-radio-field {
		border-bottom: 1px solid #ced4da;
		padding-bottom: 4px;
	}

	&:last-child .form-radio-field {
		border-bottom: 0;
		padding-bottom: 0;
	}
}



label {
	display:inline-block;
	margin-bottom: 0.25rem;
	font-weight: 600;
	color: $blue;

	&.radio-label {
		color: black;
		font-weight: 400;
		margin-bottom: 0;
	}

	&.accordion-label {
		color: white;
		margin-bottom: 0;
	}
}

legend {
	font-weight: 600;
	color: $blue;
}

.\!mb-0 {
	margin-bottom: 0 !important;
}
.gap-x-4 {
	column-gap: 1.5rem;
}
.gap-y-2 {
	row-gap: 0.5rem;
}
.gap-y-3 {
	row-gap: 1rem;
}
.gap-y-4 {
	row-gap: 1.5rem;
}
.text-sm {
	font-size: 0.875rem;
}
.text-md {
	font-size: 1rem;
}
.text-lg {
	font-size: 1.25rem;
}

.content-wrapper img {
	max-width: 100%;
	height: auto;
}

.fancybox__container {
	z-index: 10000;

	.fancybox__backdrop {
		background: rgba(0,0,0,0.85);
	}

	img {
		max-width: 100%;
		height: auto;
		width: 100%;
	}
}

@each $breakpoint, $max-width in $fancybox-max-widths {
	@media screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
		.fancybox__container__max_width .fancybox__content {
			max-width: $max-width;
		}
	}
}
