body #pageBackground {
	pointer-events: none;
	top: $header-height;
	position: fixed;
	height: 40vh;
	width: 100%;
	z-index: -1;
	left: 0;

	video {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

}