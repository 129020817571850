.card {
	border: none;
	border-top: 3px solid $blue;
	border-radius: 0;

	.card-body {
		border: 1px solid $grey;
		padding: 3.125rem;
		border-top: none;

		.social-links {
			list-style: none;
			padding: 0;
			margin:0;
			display: flex;

			li {
				margin-right: 1.25rem;
				font-size: 1.75rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}