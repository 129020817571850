.why-choose-us {
	max-width: 100%;
  overflow: hidden;

	&__text {
		padding: 10%;

		h4 {
			color: $blue;
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
	}

	&__image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		&__img {
			@media (min-width: 768px) {
				display: none;
			}
		}
	}
}