.content-card-container {

	.content-card {

		.inner-content-card {
			justify-content: flex-start;
			border: 1px solid $grey;
			flex-direction: column;
			align-items: center;
			padding: 3.125rem;
			display: flex;
			height: 100%;

			.image {
				justify-content: center;
				align-items: center;
				min-height: 120px;
				display: flex;
			}

			.title {
				font-weight: 800;
				color: $blue;
				letter-spacing: 0.4px;
			}
		}

	}

}

#logistics-content-wrapper {
	.content-card {
		padding: 30px;
	}

	.inner-content-card {
    border: none;
	}

	.content-card:nth-child(1),
	.content-card:nth-child(4) {
		.inner-content-card {
			background-color: $light-grey;
		}
	}

	.content-card:nth-child(2),
	.content-card:nth-child(3) {
		.inner-content-card {
			background-color: $blue;

			.title {
				color: white !important;
			}
			
			p {
				color: white !important;
			}
		}
	}

	.title {
		font-size: 24px;
	}
}