body.page-type-newsArchive {
	.fancybox__container.fancybox__news_item {
		.fancybox__content {
			padding: 6.5rem 6rem;
		}
	}
	.news-item-container {
		.news-item {
			.inner-news-item {
				transition: opacity 175ms ease-out;
				text-decoration: none;
				display: block;
				opacity: 1;

				&:hover {
					opacity: 0.6;
				}

				.image {
					border: 1px solid $grey;
					min-height: 265px;
				}

				.info {
					flex-direction: column;
					margin-top: 1.25rem;
					display: flex;

					span {
						text-transform: uppercase;
						display: inline-flex;
						color: $dark-grey;
						font-weight: 600;

						&.title {
							font-weight: 800;
							color: $blue;

							&::after {
								font-family: 'Font Awesome 6 Pro';
								margin-left: 0.5rem;
								position: relative;
								font-weight: 900;
								content: '\f061';
							}
						}
					}
				}
			}
		}
	}
}